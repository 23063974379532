import { useLocation } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react'
import axios from 'axios';
import { getRedirectUrl } from '../../consts';


const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export default () => {

    const [redirect, setRedirect] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const query = useQuery();

    useEffect(() => {
        const token = query.get("oauth_token");
        const verifier = query.get("oauth_verifier");
        const API_URL = getRedirectUrl() + "/garmin"
        if (token && verifier) {
            axios.post(API_URL, JSON.stringify({ token, verifier }), {headers: {'Content-Type': 'application/json',}} )
                .then((res) => {
                    const data = JSON.parse(res.data);
                    const {error, message } = data;
                    if (error) {
                        setError(true)
                        setTimeout(() => {
                            window.location.assign('myavos://app/devices')
                        }, 300);
                        return;
                    }
                    setRedirect(true);
                    window.location.assign('myavos://app/devices')
                })
                .catch((err) => {
                    console.error(err);
                    setError(true)
                    setTimeout(() => {
                        window.location.assign('myavos://app/devices')
                    }, 300)
                })
        } else {
            setError(true);
            setTimeout(() => {
                window.location.assign('myavos://app/devices')
            }, 300)
        }
    }, [])

    return (
        <div >
            {
                error ?
                    <div className='flex flex-row space-x-1 justify-center items-center'> 
                        Unknown error occured, please try again.
                    </div>
                    :
                    <span> {redirect ? <div className="flex flex-col space-x-1 justify-center items-center">
                        <span>
                            Successfully linked myAvos to garmin, App should be launched automatically.
                        </span>
                        <span>click this <a className='underline' style={{ color: '#38bdf8' }} href='myavos://app/devices'>link</a> to launch the app manually. </span>
                    </div>
                        :
                        <div className="flex flex-row space-x-1 justify-center items-center">
                            <span>
                                Connecting to garmin
                            </span>
                            <div style={{ animationDelay: '0.1s' }} className="bg-orange-600 p-1 w-2 h-2 rounded-full animate-bounce"></div>
                            <div style={{ animationDelay: '0.2s' }} className="bg-yellow-600 p-1 w-2 h-2 rounded-full animate-bounce "></div>
                            <div style={{ animationDelay: '0.3s' }} className="bg-red-600 p-1 w-2 h-2 rounded-full animate-bounce"></div>
                        </div>}
                    </span>
            }

        </div>
    )
}